<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

/**
 * Starter component
 */
export default {
  page: {
    title: "Starter Page",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Settings",
      items: [
        {
          text: "Utility",
          href: "/"
        },
        {
          text: "Starter Page",
          active: true
        }
      ]
    };
  }
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

 <div class="col-lg-6">
            <b-card header-class="bg-transparent border-primary" class="border border-primary">

                <p class="card-text">
                  <div class="row">
                    <div class="col-12">
                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="gcashName"
                        >GCash Name</label
                      >
                      <div class="col-md-9">
                        <input
                          id="gcashName"
                          type="text"
                          class="form-control"
                          name="gcashName"
                          placeholder="Insert GCash Name"
                        />
                      </div>
                    </div>

                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="gcashName"
                        >GCash Number</label
                      >
                      <div class="col-md-9">
                        <input
                          id="gcashName"
                          type="text"
                          class="form-control"
                          name="gcashName"
                          placeholder="Insert GCash Number"
                        />
                      </div>
                    </div>

                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="password"
                        >Password</label
                      >
                      <div class="col-md-9">
                        <input
                          id="password"
                          type="password"
                          name="password"
                          class="form-control"
                          placeholder="Enter Password"
                        />
                      </div>
                    </div>
                    
                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="confirmpass"
                        >Re Password</label
                      >
                      <div class="col-md-9">
                        <input
                          id="confirmpass"
                          type="password"
                          name="confirmpass"
                          class="form-control"
                          placeholder="Re-enter Password"
                        />
                      </div>
                    </div>

                  </div>
                  </div>
                </p>
            </b-card>
        </div>
    
  </Layout>
</template>